/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;

@include mat.core();

$dark-primary-text: #A2A2A2;
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: #A2A2A2;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$my-palette: (
        50: #e8eaf6,
        100: #c5cae9,
        200: #9fa8da,
        300: #7986cb,
        400: #5c6bc0,
        500: #3f51b5,
        600: #3949ab,
        700: #303f9f,
        800: #283593,
        900: #1a237e,
        A100: #8c9eff,
        A200: #536dfe,
        A400: #3d5afe,
        A700: #304ffe,
        contrast: (
                50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $light-primary-text,
                400: $light-primary-text,
                500: $light-primary-text,
                600: $light-primary-text,
                700: $light-primary-text,
                800: $light-primary-text,
                900: $light-primary-text,
                A100: $dark-primary-text,
                A200: $light-primary-text,
                A400: $light-primary-text,
                A700: $light-primary-text,
        )
);

$my-primary: mat.define-palette($my-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-typography: mat.define-typography-config(
        $font-family: 'Poppins'
);

$my-theme: mat.define-dark-theme((
        color: (
                primary: $my-primary,
                accent: $my-accent
        ),
        typography: $my-typography,
));

@include mat.all-component-themes($my-theme);

//@import "@angular/material/prebuilt-themes/purple-green.css";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

//=================================================
// Number Input arrows
//=================================================

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

//=================================================
// Rounded Input Boxes
//=================================================

.custom-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  border-radius: 0 12px 12px 0;
}

.custom-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading {
  border-radius: 12px 0 0 12px;
}

//=================================================
// Circle Checkboxes
//=================================================

.custom-field .mdc-checkbox__background {
  border-radius: 70% !important;
}


//=================================================
// Form Card Message Styles
//=================================================

.form-message {
  margin: 0 0 20px 0;

  p {
    margin: 0;
    padding: 10px 10px;

    &.success {
      background-color: rgba(33,187,150, 0.1);
      color: rgba(162,162,162);
    }

    &.info {
      background-color: rgba(122,119,255, 0.1);
      color: rgba(162,162,162);
    }

    &.error {
      background-color: rgba(239,68,68, 0.1);
      color: rgba(248,113,113);
    }
  }
}

//=================================================
// Paginator Styles
//=================================================

.mat-mdc-paginator-container {
  padding: 0!important;
}

//=================================================
// Button Styles
//=================================================

.mat-mdc-raised-button.mat-primary {
 color: #ffffff !important;
}

//=================================================
// Chip Styles
//=================================================

.mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  margin: 0 !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic {
  border-radius: 10px;
  padding: 10px 10px;
  color: #A2A2A2 !important;
  background-color: transparent !important;
  border: 1px solid #A2A2A2;
}

.mat-mdc-standard-chip.mdc-evolution-chip {
  margin: 0 5px !important;

  &:first-of-type {
    margin-left: 0 !important;
  }

  &:last-of-type {
    margin-right: 0 !important;
  }
}

.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
  border: 1px solid #4C4AD2;
}

.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: #4C4AD2 !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary {
  padding: 0 10px !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
  display: none !important;
}
